import { Component, OnInit } from '@angular/core';
import {
  DeviceUpdateStatus,
  ExtendedReportingService,
} from 'src/app/services/extended-reporting.service';

@Component({
  selector: 'app-device-update-status',
  templateUrl: './device-update-status.component.html',
  styleUrls: ['./device-update-status.component.scss'],
})
export class DeviceUpdateStatusComponent implements OnInit {
  deviceStatuses: DeviceUpdateStatus[] = [];
  isLoading = true;

  constructor(private extendedReportingService: ExtendedReportingService) {}

  ngOnInit(): void {
    // Fetch device update statuses on component init
    this.extendedReportingService.getDeviceUpdateStatus().subscribe(
      (data) => {
        this.isLoading = false;
        this.deviceStatuses = data;
        console.log('Fetched Device Statuses: ', this.deviceStatuses);
      },
      (error) => {
        console.error('Error fetching device statuses: ', error);
      }
    );
  }

  downloadCsv(): void {
    this.extendedReportingService.getDeviceUpdateStatusAsCsv();
  }
}
