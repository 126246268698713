<div class="card table-container-card">
    <div class="table-heading" style="border-bottom: none; margin-bottom: 0px !important;">
        <div class="table-title">
            <cds-icon shape="history" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
            <h3>Application Lifecycle Tracking</h3>
        </div>
        <div class="action-group">
            <button (click)="downloadCsv()" type="button" class="btn btn-icon btn-primary btn-md" #tooltip="matTooltip"
                matTooltip="Download the data as a .csv file" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
                <cds-icon shape="download"></cds-icon> Download CSV
            </button>
        </div>
    </div>
    <clr-datagrid id="datagrid" [clrDgLoading]="isLoading" class="datagrid-compact" style="width: 100%;">
        <clr-dg-column>Application</clr-dg-column>
        <clr-dg-column>Version</clr-dg-column>
        <clr-dg-column>Test</clr-dg-column>
        <clr-dg-column>Acceptance</clr-dg-column>
        <clr-dg-column>Production</clr-dg-column>

        <clr-dg-row *ngFor="let item of actionVersions; let i = index">
            <clr-dg-cell>{{ item.actionName }}</clr-dg-cell>
            <clr-dg-cell>{{ item.version }}</clr-dg-cell>
            <clr-dg-cell>{{ item.testDate | date:'short' }}</clr-dg-cell>
            <clr-dg-cell>{{ item.acceptanceDate | date:'short' }}</clr-dg-cell>
            <clr-dg-cell>{{ item.productionDate | date:'short' }}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
            <clr-dg-pagination [(clrDgPage)]="currentPage" [clrDgPageSize]="itemsPerPage"
                [clrDgTotalItems]="actionVersionsCount" (clrDgPageChange)="onChangePage($event)">
                <!-- <clr-dg-page-size [(clrPageSizeOptions)]="pageSizeOptions" (clrPageSizeChange)="onChangePageSize($event)">
                    Items per page
                </clr-dg-page-size> -->
                {{ getStartItem() }} - {{ getEndItem() }} of {{ actionVersionsCount }}
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</div>