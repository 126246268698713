import { Component, OnInit, ViewChild } from '@angular/core';
import { ActionService } from 'src/app/modules/shared/services/action.service';
import _ from 'lodash';
import { TagService } from 'src/app/services/tag.service';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';
import { IBluePrint } from 'src/app/domain/interfaces/iblueprint';
import { BlueprintService } from 'src/app/services/blueprint.service';
import { AssignmentActionService } from 'src/app/services/assignmentaction.service';
import { IAssignmentAction } from 'src/app/domain/interfaces/iassignmentlist';
import { ActionStatus } from 'src/app/domain/enums/action-status';
import { ITag, TagType } from 'src/app/modules/shared/domain/tag';
import { ActionDto } from 'src/app/modules/shared/domain/action-dto';
import { ActionVersionDto } from 'src/app/domain/dtos/action-version-dto';
import { NotifyMeComponent } from '../../notify-me/notify-me.component';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit {
  @ViewChild('cmp', { static: false }) notifyMe: NotifyMeComponent;

  selectedActions: ActionDto[] = [];
  systemTags: ITag[] = [];
  userTags: ITag[] = [];
  defaultTag: ITag;
  newSelectedTag: ITag;
  addActionsToTag: boolean = false;
  addTagsToActionVersions: boolean = false;
  newVersionNumber: string = '0.0.0.0';
  addNewPackageVersionModal: boolean = false;
  addNewActionVisible: boolean = false;
  editActionVisible: boolean = false;
  detailState: ActionDto;
  actionAssignments: IAssignmentAction[] = [];
  actionStatus = ActionStatus;

  originalActions: ActionDto[];
  actions: ActionDto[];
  toggleDisabledActions: boolean = false;
  openDisableModal: boolean = false;
  statusBool: boolean;
  versionDtos: ActionVersionDto[];
  versionsAreLoading: boolean = false;
  isLoadingPackages = true;

  actionBlueprints: IBluePrint[] = [];
  versionsBeingDeleted: ActionVersionDto[];

  constructor(
    private actionService: ActionService,
    private tagService: TagService,
    private actionVersionService: ActionversionService,
    private assignmentActionService: AssignmentActionService,
    private blueprintService: BlueprintService
  ) {}

  ngOnInit(): void {
    this.GetActionList();
    this.GetTags();
  }

  GetActionList() {
    this.actionService.GetActionDtos().subscribe((actionDtos) => {
      //@ts-ignore
      this.newLength = actionDtos.length;
      this.actions = actionDtos.filter((a) => a.versionCount > 0);
      this.isLoadingPackages = false;
    });
  }

  clickName(dom) {
    dom._view._lView[0].parentElement.previousSibling.children[1].children[0].click();
  }

  onDetailOpen(e: ActionDto) {
    if (e !== null) {
      this.versionsBeingDeleted = [];
      this.actionBlueprints = [];
      this.actionAssignments = [];
      this.versionDtos = [];
      this.versionsAreLoading = true;

      this.blueprintService.GetBlueprintsWithAction(e.id).subscribe((data) => {
        this.actionBlueprints = data;
      });
      this.assignmentActionService
        .getAssignmentActionsWithActionId(e.id)
        .subscribe((data) => {
          this.actionAssignments = data;
        });
      this.actionService.GetActionByID(e.id).subscribe((action) => {
        this.versionsAreLoading = false;
        this.versionDtos = action.actionVersion
          .filter((av) => !av.deletedAt)
          .map((av) => {
            return {
              ...av,
              tag: av.actionVersionTag[0].tag,
              actionName: action.name,
              hasBuilderJson: av.hasBuilderJson,
            };
          });
      });
    }
  }

  actionSelectionChanged(elements: ActionDto[]) {
    this.selectedActions = elements;
  }

  handleCancelTagActionModal() {
    this.addActionsToTag = false;
  }

  onVersionLifecycleUpdate(lifecycleId: number, versionId: number) {
    const selectedLifecycle = this.systemTags.filter(
      (t) => t.id === lifecycleId
    );
    this.actionVersionService
      .AddTagToActionVersionByActionVersionId(versionId, selectedLifecycle)
      .subscribe();
  }

  handleCancelTagActionVersionModal() {
    this.addTagsToActionVersions = false;
  }

  handleCancelNewPackageVersionModal() {
    this.addNewPackageVersionModal = false;
  }

  GetFilteredActions(disabledActions) {
    if (disabledActions) {
      this.actions = _.cloneDeep(this.originalActions);
    } else {
      this.actions = _.cloneDeep(
        this.originalActions.filter((v) => v.isActive == true)
      );
    }
  }

  SetStatus(activeBool: boolean) {
    const actionIds = [];
    this.selectedActions.forEach((element) => {
      actionIds.push(element.id);
    });
    this.actionService
      .UpdateActionStatus(actionIds, activeBool)
      .subscribe((v) => {
        this.GetActionList();
        this.openDisableModal = false;
      });
  }

  DeleteActionById(id: number) {
    this.actionService.RemoveSingleActionByID(id).subscribe((v) => {
      this.GetActionList();
    });
  }

  DeleteAssignments() {
    for (let index = 0; index < this.selectedActions.length; index++) {
      this.actionService
        .RemoveSingleActionByID(this.selectedActions[index].id)
        .subscribe((v) => {
          this.GetActionList();
        });
    }
  }

  GetTags() {
    this.tagService.GetTags().subscribe((tags) => {
      this.userTags = tags.filter((tag) => tag.type == TagType.User);
      this.systemTags = tags.filter((tag) => tag.type == TagType.DeployStage);
      this.defaultTag = this.userTags.filter((v) => v.name == 'Unreleased')[0];
      this.newSelectedTag = _.cloneDeep(this.defaultTag);
    });
  }

  toggleDetachedInstall(actionId: number, value: boolean): void {
    this.actionService.setIsDetachedInstall(actionId, value).subscribe();
  }

  closeDetail(): void {
    this.detailState = null;
  }

  toggleRebootRequired(actionId: number, value: boolean): void {
    this.actionService.setIsRebootRequired(actionId, value).subscribe();
  }

  moveActionVersionToRecycleBin(versionId: number): void {
    const version = this.versionDtos.find((v) => v.id === versionId);
    this.versionsBeingDeleted = [...this.versionsBeingDeleted, version];

    this.actionVersionService
      .moveActionVersionToRecycleBin(versionId)
      .subscribe({
        next: () => {
          this.versionDtos = this.versionDtos.filter((v) => v.id !== versionId);
          if (!this.versionDtos.length) {
            this.actions = this.actions.filter(
              (a) => a.id !== this.detailState.id
            );

            this.detailState = null;
          }
        },
        error: (error) => {
          console.error(error);
          this.versionsBeingDeleted = this.versionsBeingDeleted.filter(
            (v) => v.id !== versionId
          );
          if (error.status === 400) {
            this.notify(
              'Failed to delete: This version is assigned to an endpoint.',
              'danger'
            );
          }
        },
        complete: () =>
          (this.versionsBeingDeleted = this.versionsBeingDeleted.filter(
            (v) => v.id !== versionId
          )),
      });
  }

  isBeingDeleted(versionId: number): boolean {
    return !!this.versionsBeingDeleted.find((v) => v.id === versionId);
  }

  notify(text: string, status: string): void {
    var data = {
      defaultNotificationText: text,
      standardStatus: status,
      notificationShow: true,
    };
    this.notifyMe.showNotification(data);
  }
}
