<div class="card table-container-card">
  <div class="table-heading" style="border-bottom: none; margin-bottom: 0px !important">
    <div class="table-title">
      <cds-icon shape="applications" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
      <h3>Device Update Status</h3>
    </div>
    <div class="action-group">
      <button (click)="downloadCsv()" type="button" class="btn btn-icon btn-primary btn-md" #tooltip="matTooltip"
        matTooltip="Download the data as a .csv file" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <cds-icon shape="download"></cds-icon> Download CSV
      </button>
    </div>
  </div>

  <clr-datagrid id="datagrid" [clrDgLoading]="isLoading" class="datagrid-compact w-100">
    <clr-dg-column [clrDgField]="'deviceName'">Device</clr-dg-column>
    <clr-dg-column [clrDgField]="'installedApplications'">Installed Applications</clr-dg-column>
    <clr-dg-column [clrDgField]="'updatedApplications'">Up-To-Date Applications</clr-dg-column>
    <clr-dg-column [clrDgField]="'compliance'">Compliance (%)</clr-dg-column>

    <clr-dg-row *clrDgItems="let status of deviceStatuses">
      <clr-dg-cell>{{ status.deviceName }}</clr-dg-cell>
      <clr-dg-cell>{{ status.installedApplications }}</clr-dg-cell>
      <clr-dg-cell>{{ status.updatedApplications }}</clr-dg-cell>
      <clr-dg-cell>{{ status.compliance }} (%)</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="30">
        <clr-dg-page-size [clrPageSizeOptions]="[30, 50, 100]">
          Devices per page
        </clr-dg-page-size>
        {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ pagination.totalItems }} Devices
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>