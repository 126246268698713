<app-notify-me #cmp></app-notify-me>

<div class="card table-container-card">
  <div class="table-heading" style="border-bottom: none; margin-bottom: 0px !important;">
    <div class="table-title">
      <cds-icon shape="list" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
      <h3>Packages</h3>
    </div>
    <div class="action-group">
      <button type="button" class="btn btn-icon btn-outline btn-md" routerLink="configure-lifecycle"
        #tooltip="matTooltip" matTooltip="Configure auto lifecycle settings" [matTooltipPosition]="'above'"
        matTooltipHideDelay="100">
        <cds-icon shape="cog"></cds-icon> Auto Lifecycle
      </button>
      <button type="button" class="btn btn-icon btn-primary btn-md" routerLink="lifecycle" #tooltip="matTooltip"
        matTooltip="Adjust the package lifecycles" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <cds-icon shape="process-on-vm"></cds-icon> Lifecycles
      </button>
    </div>
  </div>

  <clr-datagrid id="my-datagrid" [clrDgSelected]="selectedActions" [clrDgLoading]="isLoadingPackages"
    (clrDgSelectedChange)="actionSelectionChanged($event)" class="datagrid-compact" style="width: 100%;">
    <clr-dg-column [clrDgField]="'name'">Package</clr-dg-column>
    <clr-dg-column>Package Versions</clr-dg-column>
    <clr-dg-row *clrDgItems="let action of actions" [clrDgItem]="action" style="cursor: pointer;">
      <clr-dg-cell #refEl (click)="clickName(refEl)" style="display: flex; height: 40px;
      justify-content: space-between;">{{action.name}}
      </clr-dg-cell>
      <clr-dg-cell #refEl (click)="clickName(refEl)">
        {{action.versionCount}}
      </clr-dg-cell>
      <clr-dg-cell *ngIf="action.actionType.name=='Command Prompt'" #refEl (click)="clickName(refEl)">Command
        Prompt</clr-dg-cell>
      <clr-dg-cell *ngIf="action.actionType.name=='Powershell'" #refEl
        (click)="clickName(refEl)">Powershell</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="100">
        <clr-dg-page-size [clrPageSizeOptions]="[15,30,45,60,75,90,100]">Packages per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Packages
      </clr-dg-pagination>
    </clr-dg-footer>
    <ng-template [(clrIfDetail)]="detailState" let-detail (clrIfDetailChange)="onDetailOpen($event)">
      <clr-dg-detail>
        <clr-dg-detail-header>
          <div style="display: flex; justify-content: space-between;">
            <span>{{detail.name}}</span>
            <button class="btn btn-link" aria-label="close details" (click)="closeDetail()">
              <cds-icon shape="window-close" size="md" style="--color: var(--clr-p1-color)"></cds-icon>
            </button>
          </div>
          <clr-toggle-container class="mt-2">
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle name="detachedInstallToggle" [(ngModel)]="detail.isDetachedInstall"
                (change)="toggleDetachedInstall(detail.id, detail.isDetachedInstall)" />
              <label>Detached Install</label>
            </clr-toggle-wrapper>
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle name="rebootRequiredToggle" [(ngModel)]="detail.isRebootRequired"
                (change)="toggleRebootRequired(detail.id, detail.isRebootRequired)" />
              <label>Reboot Required</label>
            </clr-toggle-wrapper>
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle name="overrideLifecycleManagement"
                [(ngModel)]="detail.hasOverridenGlobalAutoLifecycle"
                (change)="updateAutoLifecycleConfigurationForAction(detail)" />
              <label>Override Auto Lifecycle</label>
            </clr-toggle-wrapper>
          </clr-toggle-container>
          <clr-select-container *ngIf="detail.hasOverridenGlobalAutoLifecycle">
            <label>Testing to Acceptance time <cds-icon matTooltipHideDelay="100"
                matTooltip="Wait time before automatically flowing to acceptance" shape="info-circle"
                size="'sm'"></cds-icon></label>
            <select class="clr-select" clrSelect name="testingToAcceptance" [(ngModel)]="detail.flowToAcceptanceInHours"
              (change)="updateAutoLifecycleConfigurationForAction(detail)">
              <option [ngValue]="null">Never</option>
              <option value="1">1 hour</option>
              <option value="2">2 hours</option>
              <option value="4">4 hours</option>
              <option value="8">8 hours</option>
              <option value="24">1 day</option>
              <option value="48">2 days</option>
              <option value="96">4 days</option>
              <option value="168">1 week</option>
              <option value="336">2 weeks</option>
            </select>
          </clr-select-container>
          <clr-select-container *ngIf="detail.hasOverridenGlobalAutoLifecycle">
            <label>Acceptance to Production time<cds-icon matTooltipHideDelay="100"
                matTooltip="Wait time before automatically flowing to Production" shape="info-circle"
                size="'sm'"></cds-icon></label>
            <select class="clr-select" clrSelect name="testingToAcceptance" [(ngModel)]="detail.flowToProductionInHours"
              (change)="updateAutoLifecycleConfigurationForAction(detail)">
              <option [ngValue]="null">Never</option>
              <option value="1">1 hour</option>
              <option value="2">2 hours</option>
              <option value="4">4 hours</option>
              <option value="8">8 hours</option>
              <option value="24">1 day</option>
              <option value="48">2 days</option>
              <option value="96">4 days</option>
              <option value="168">1 week</option>
              <option value="336">2 weeks</option>
            </select>
          </clr-select-container>
          <div class="executionTime mb-2 pt-0">
            <small>
              <p>Execution time limit in seconds (0 to disable timeout): </p>
              <input clrInput max="5400" min="0" type="number" [(ngModel)]="detail.executionTimeout"
                (change)="setExecutionTime(detail.id, detail.executionTimeout)" />
            </small>
          </div>
        </clr-dg-detail-header>
        <clr-dg-detail-body>
          <clr-tabs>
            <clr-tab>
              <button clrTabLink>Versions</button>
              <clr-tab-content *clrIfActive="true">
                <clr-datagrid [clrDgLoading]="versionsAreLoading" class="datagrid-compact" id="my-datagrid">
                  <clr-dg-column>Package Version</clr-dg-column>
                  <clr-dg-column>Lifecycle</clr-dg-column>
                  <clr-dg-column>Action</clr-dg-column>
                  <clr-dg-row *clrDgItems="let version of versionDtos" [clrDgItem]="version">
                    <clr-dg-cell>{{version.version}}</clr-dg-cell>
                    <clr-dg-cell>
                      <select class="clr-select" clrSelect *ngIf="version.tag" name="lifecycle"
                        [ngModel]="version.tag.id"
                        (change)="onVersionLifecycleUpdate(+$event.target.value, version.id)">
                        <option *ngFor="let tag of systemTags" value="{{tag.id}}">{{tag.name}}</option>
                      </select>
                    </clr-dg-cell>
                    <clr-dg-cell>
                      <button [disabled]="!version.hasBuilderJson" type="button" class="btn btn-icon btn-sm m-0"
                        routerLink="/package-builder" [queryParams]="{id: version.id}" #tooltip="matTooltip"
                        matTooltip="Open the package builder" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
                        <cds-icon shape="pencil"></cds-icon>
                      </button>
                      <div class="mx-1"></div>
                      <button (click)="moveActionVersionToRecycleBin(version.id)"
                        [disabled]="isBeingDeleted(version.id)" type="button"
                        class="btn btn-icon btn-danger-outline btn-sm m-0" #tooltip="matTooltip"
                        matTooltip="Move to recycle bin" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
                        <cds-icon *ngIf="!isBeingDeleted(version.id)" shape="trash"></cds-icon>
                        <cds-icon *ngIf="isBeingDeleted(version.id)" shape=""></cds-icon>
                      </button>
                      <div class="mx-1"></div>
                      <clr-toggle-wrapper>
                        <input type="checkbox" clrToggle name="overrideLifecycleManagement"
                          [ngModel]="!version.isPreventingAutomaticLifecycleUpdates"
                          (change)="updateVersionAutoLifecycle(version, version.isPreventingAutomaticLifecycleUpdates)" />
                        <label>Auto Lifecycle</label>
                      </clr-toggle-wrapper>
                    </clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Versions per page</clr-dg-page-size>
                      {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of {{paginationBP.totalItems}}
                      versions
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>

              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Endpoints</button>
              <clr-tab-content>
                <clr-datagrid class="datagrid-compact h-100">
                  <clr-dg-column>Endpoint</clr-dg-column>
                  <clr-dg-column>Status</clr-dg-column>
                  <clr-dg-column></clr-dg-column>
                  <clr-dg-row *clrDgItems="let assignmentAction of actionAssignments" [clrDgItem]="version">
                    <clr-dg-cell>{{assignmentAction.assignment.endpoint.name}}</clr-dg-cell>
                    <clr-dg-cell>{{assignmentAction.assignment.endpoint.endpointStatus.name}}</clr-dg-cell>
                    <clr-dg-cell [ngSwitch]="assignmentAction.actionStatus">
                      <span *ngSwitchCase="actionStatus.UpToDate">
                        <cds-icon shape="success-standard" status="success" size="sm" solid></cds-icon>
                        Up to date
                      </span>
                      <span *ngSwitchCase="actionStatus.NotUpToDate">
                        <cds-icon shape="warning-standard" status="warning" size="sm" solid></cds-icon>
                        Update available
                      </span>
                      <span *ngSwitchDefault>Not Installed</span>
                    </clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Endpoints per page</clr-dg-page-size>
                      {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of {{paginationBP.totalItems}}
                      Endpoints
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Blueprints</button>
              <clr-tab-content>
                <clr-datagrid class="datagrid-compact h-100">
                  <clr-dg-column>Blueprint</clr-dg-column>
                  <clr-dg-row *clrDgItems="let bp of actionBlueprints" [clrDgItem]="version">
                    <clr-dg-cell>{{bp.name}}</clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Blueprints per page</clr-dg-page-size>
                      {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of {{paginationBP.totalItems}}
                      Blueprints
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Suggestions</button>
              <clr-tab-content>
                <nd-package-suggestions-tab [action]="detail" [packages]="actions"></nd-package-suggestions-tab>
              </clr-tab-content>
            </clr-tab>
          </clr-tabs>
        </clr-dg-detail-body>
      </clr-dg-detail>
    </ng-template>
  </clr-datagrid>
</div>