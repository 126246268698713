import { Component, OnInit } from '@angular/core';
import { ActionVersionDto } from 'src/app/domain/dtos/action-version-dto';
import { PaginationModel } from 'src/app/domain/interfaces/paginationModel';
import { ITag, TagType } from 'src/app/modules/shared/domain/tag';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';
import { ExtendedReportingService } from 'src/app/services/extended-reporting.service';
import { TagService } from 'src/app/services/tag.service';

@Component({
  selector: 'app-application-lifecycle-tracking',
  templateUrl: './application-lifecycle-tracking.component.html',
  styleUrls: ['./application-lifecycle-tracking.component.scss'],
})
export class ApplicationLifecycleTrackingComponent implements OnInit {
  isLoading = false;
  actionVersions: ActionVersionDto[];
  actionVersionsCount: number;
  paginationModel: PaginationModel;
  pageSizeOptions = [40, 60, 80, 100];
  currentPage = 1;
  itemsPerPage = 40;
  lifecycles: ITag[];

  constructor(
    private actionVersionService: ActionversionService,
    private reportingService: ExtendedReportingService,
    private tagService: TagService
  ) {}

  ngOnInit(): void {
    this.paginationModel = { skip: 0, take: this.itemsPerPage };
    this.tagService.GetTags().subscribe({
      next: (data) =>
        (this.lifecycles = data.filter((d) => d.type === TagType.DeployStage)),
    });
    this.getActionVersions();
  }

  // Fetch action versions from the server
  getActionVersions() {
    this.isLoading = true;
    this.actionVersionService
      .GetActionVersions(this.paginationModel)
      .subscribe((data) => {
        this.actionVersions = data.actionVersions;
        this.actionVersionsCount = data.count;
        this.isLoading = false;
      });
  }

  // Handle page change
  onChangePage(page: number) {
    this.currentPage = page;
    this.paginationModel.skip = (this.currentPage - 1) * this.itemsPerPage;
    this.paginationModel.take = this.itemsPerPage;
    this.getActionVersions();
  }

  // Handle page size change
  onChangePageSize(pageSize: number) {
    this.itemsPerPage = pageSize;
    this.paginationModel.take = pageSize;
    this.paginationModel.skip = 0; // Reset to first page
    this.getActionVersions();
  }

  // Helper method to calculate the end of the range for pagination
  getEndItem(): number {
    return Math.min(
      this.currentPage * this.itemsPerPage,
      this.actionVersionsCount
    );
  }

  // Helper method to calculate the start of the range for pagination
  getStartItem(): number {
    return (this.currentPage - 1) * this.itemsPerPage + 1;
  }

  downloadCsv(): void {
    this.reportingService.getApplicationLifecycleStatusAsCsv();
  }
}
