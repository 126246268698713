<clr-datagrid [clrDgLoading]="isLoadingLinks || isLoadingAcceptance">
    <clr-dg-column>Application</clr-dg-column>
    <clr-dg-column>Endpoint</clr-dg-column>
    <clr-dg-column>Package</clr-dg-column>
    <clr-dg-column></clr-dg-column>

    <clr-dg-row *ngFor="let item of links; let i = index">
        <clr-dg-cell>

            <span class="app-info-wrapper"><cds-icon shape="info-circle" [size]="'sm'" [solid]="true" [status]="'info'"
                    class="app-info"
                    matTooltip="{{item.link.vendor === null ? item.link.version : item.link.vendor + ' - v' + item.link.version}}"
                    matTooltipHideDelay="100"></cds-icon></span> {{ item.link.displayName
            }}</clr-dg-cell>
        <clr-dg-cell>
            {{item.link.endpoint.name}}
        </clr-dg-cell>
        <clr-dg-cell>
            <input [disabled]="item.link.isConfirmed || isLoadingAcceptance" clrInput placeholder="Package"
                name="Package" [matAutocomplete]="auto" [(ngModel)]="item.selectedName"
                (input)="onInputValueChanged($event)" size="35" />
            <mat-autocomplete panelWidth="'auto'" #auto requireSelection autoActiveFirstOption>
                <mat-option *ngFor="let package of filteredPackages" [value]="package.name">
                    {{package.name}}
                </mat-option>
            </mat-autocomplete>
        </clr-dg-cell>
        <clr-dg-cell class="d-flex justify-content-end">
            <button [disabled]="item.link.isConfirmed || isLoadingAcceptance" class="btn btn-success btn-sm m-0"
                (click)="onConfirmLink(item.link)">
                <cds-icon shape="check"></cds-icon>
                {{item.link.isConfirmed ? 'Confirmed' : 'Confirm'}}
            </button>
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-pagination #applicationPackageLinkTable [(clrDgPage)]="currentPage" [clrDgPageSize]="itemsPerPage"
            [clrDgTotalItems]="count" (clrDgPageChange)="onChangePage($event)">
            <clr-dg-page-size [clrPageSizeOptions]="[10]">Items per page</clr-dg-page-size>
            {{applicationPackageLinkTable.firstItem + 1}} - {{applicationPackageLinkTable.lastItem + 1}}
            of {{applicationPackageLinkTable.totalItems}}
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<app-notify-me #cmp></app-notify-me>