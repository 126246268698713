import { Component, OnInit } from '@angular/core';
import {
  ApplicationUpdateStatus,
  ExtendedReportingService,
} from 'src/app/services/extended-reporting.service';

@Component({
  selector: 'app-applications-update-status',
  templateUrl: './applications-update-status.component.html',
  styleUrls: ['./applications-update-status.component.scss'],
})
export class ApplicationsUpdateStatusComponent implements OnInit {
  deviceStatuses: ApplicationUpdateStatus[] = [];
  isLoading = true;

  constructor(private extendedReportingService: ExtendedReportingService) {}

  ngOnInit(): void {
    // Fetch device update statuses on component init
    this.extendedReportingService.getApplicationUpdateStatus().subscribe(
      (data) => {
        this.isLoading = false;
        this.deviceStatuses = data;
        console.log('Fetched Device Statuses: ', this.deviceStatuses);
      },
      (error) => {
        console.error('Error fetching device statuses: ', error);
      }
    );
  }

  downloadCsv(): void {
    this.extendedReportingService.getApplicationUpdateStatusAsCsv();
  }
}
